<template>
    <div>
        <div v-if="item_list.length > 0">
            <div class="bg-body rounded-3 shadow-200 mb-3" v-for="(item,index) in
            item_list" :key="index">
                <div class="flex-between-center p-3">
                    <div class="flex-start-center">
                        <div class="ratio ratio-1x1 w-px-30 me-2 flex-shrink-0">
                            <img src="@/assets/img/profile_default.svg" alt="avatar" class="avatar rounded-circle w-100 object-fit-cover">
                        </div>
                        <span class="fw-bold">{{item.user_name}}</span>
                    </div>
                    <a :href="`tel:${item.user_contact}`" class="small flex-start-center rounded-pill bg-gray-relative-200 py-1 px-2 flex-shrink-0">
                        <i class="fas fa-mobile-alt text-gray-relative-600 ms-1 me-2 small"></i>
                        <span class="me-1 small">{{item.user_contact}}</span>
                        <i class="fas fa-chevron-right small ms-1 text-gray-relative-500"></i>
                    </a>
                </div>
                <hr class="cutting_line border-gray-relative-600 h-0 my-0 mx-3">
                <div class="p-3">
                    <div class="flex-start-center small mb-2">
                        <i class="fal fa-calendar text-gray-relative-400 me-2 w-px-18"></i>
                        <span>{{item.day}} ({{DayFormat(item.day)}})</span>
                    </div>
                    <div class="flex-start-center small">
                        <i class="fal fa-clock text-gray-relative-400 me-2 w-px-18"></i>
                        <span>{{TimeFormmat(item.reserve_time)}}</span>
                    </div>

                    
                    <div class="mt-3">
                        <div class="small mb-2 mt-2 badge rounded-pill"
                            :class="{'badge_success_light':item.status=='결제승인',
                                    'badge_danger_light':item.status=='결제취소' }">
                            <i class="fas me-2 w-px-18 small"
                               :class="{'fa-check':item.status=='결제승인',
                                        'fa-times':item.status=='결제취소' }"></i>
                            <span>{{item.status}}</span>
                        </div>
                    </div>
                </div>
                <div class="border-top p-3 border-gray-relative-200" v-if="item.status=='예약대기'">
                    <ul class="d-flex">
                        <li class="rounded-3 p-2 text-center bg-gray-relative-200 flex-grow-1 me-1" @click="Refuse(item)">결제취소</li>
                        <li class="rounded-3 p-2 text-center bg-main flex-grow-1 text-white" @click="Approval(item)">결제승인</li>
                    </ul>
                </div>
            </div>
        </div>
        <div v-else class="empty_data">
            <p class="text-center py-5">
                내역이 없습니다.
            </p>
        </div>
    </div>
</template>

<script>
export default {
    props:{
        item_list:{
            type:Array,
            default: []
        }
    },
    methods:{
        
        Refuse(item){
            this.$confirm(`'${item.day} (${this.DayFormat(item.day)}) ${this.TimeFormmat(item.reserve_time)}'<br>예약을 거절하시겠습니까?`).then((result)=>{
                if( result.isOk ){
                    this.$alert('예약을 거절했습니다.').then(()=>{this.$router.push('/reservation/wait')});
                }
            })
        },

        Approval(item){
            this.$confirm(`'${item.day} (${this.DayFormat(item.day)}) ${this.TimeFormmat(item.reserve_time)}'<br>예약을 승인하시겠습니까?`).then((result)=>{
                if( result.isOk ){
                    this.$alert('예약을 승인했습니다.').then(()=>{this.$router.push('/reservation/wait')});
                }
            })
        },

        // front ui
		TimeFormmat(time_str){
            if(time_str == 1){
                return '오전'
            }else{
                return '오후'
            }
		},
	    DayFormat(day_str){
            const days = ['일', '월', '화', '수', '목', '금', '토'];
            const date = new Date(day_str);
            return days[date.getDay()];
		},
    }
}
</script>

<style>

</style>