<template>
  <div class="bg-gray-relative-100 mh-vh-100-nav p-3">
    <SubHeader title="결제내역"></SubHeader>
    <ul
      class="category flex-between-center fs-px-13 gap-2 fw-bold rounded-pill bg-gray-relative-200 mb-2"
    >
      <li
        class="w-33 text-center rounded-pill py-2"
        @click="ChangeCategory('전체')"
        :class="{ 'bg-main text-white': type === '전체' }"
      >
        전체
      </li>
      <li
        class="w-33 text-center rounded-pill p-2"
        @click="ChangeCategory('결제완료')"
        :class="{ 'bg-main text-white': type === '결제완료' }"
      >
        결제 내역
      </li>
      <li
        class="w-33 text-center rounded-pill p-2"
        @click="ChangeCategory('취소완료')"
        :class="{ 'bg-main text-white': type === '취소완료' }"
      >
        취소 내역
      </li>
    </ul>
    <div class="mb-2 d-flex justify-content-end">
      <select
        class="w-33 form-select form-select-sm bg-body border-gray-relative-300 text-body"
        v-model="type"
        @change="(e) => ChangeCategory(e.target.value)"
      >
        <option
          class="text-body"
          v-for="(item, index) in status_list"
          :key="index"
          :value="item.name"
        >
          {{ item.name }}
        </option>
      </select>
    </div>
    <ul class="d-flex gap-2 mb-2">
      <li class="w-25">
        <span
          class="w-100 badge bg-white border border-gray-relative-200 text-body rounded-1 me-1 fw-light p-2 px-3"
          @click="ChangeDate(0)"
          :class="{ 'bg-gray-relative-300': cate === 0 }"
          >전체</span
        >
      </li>
      <li class="w-25">
        <span
          class="w-100 badge bg-white border border-gray-relative-200 text-body rounded-1 me-1 fw-light p-2 px-3"
          @click="ChangeDate(1)"
          :class="{ 'bg-gray-relative-300': cate === 1 }"
          >1일</span
        >
      </li>
      <li class="w-25">
        <span
          class="w-100 badge bg-white border border-gray-relative-200 text-body rounded-1 me-1 fw-light p-2 px-3"
          @click="ChangeDate(2)"
          :class="{ 'bg-gray-relative-300': cate === 2 }"
          >7일</span
        >
      </li>
      <li class="w-25">
        <span
          class="w-100 badge bg-white border border-gray-relative-200 text-body rounded-1 me-1 fw-light p-2 px-3"
          @click="ChangeDate(3)"
          :class="{ 'bg-gray-relative-300': cate === 3 }"
          >30일</span
        >
      </li>
    </ul>
    <div class="d-flex gap-2 mb-2">
      <input class="form-control" type="date" v-model="start_date" />
      <input class="form-control" type="date" v-model="end_date" />
      <button
        class="btn border-0 btn-sm bg-secondary text-white w-px-80 flex-shrink-0"
        @click="ChangeDateCategory()"
      >
        조회
      </button>
    </div>
    <ReservationItem :list="category_list" :pay_request="true" />
  </div>
</template>

<script>
import SubHeader from '@/components/common/SubHeader.vue';
import ReservationItem from '@/components/reservation/ReservationItem.vue';
const CryptoJS = require('crypto-js');
var moment = require('moment');

export default {
  components: {
    SubHeader,
    ReservationItem,
  },
  data() {
    return {
      list: [],
      category_list: [],
      type: '전체',
      status_list: [
        {
          value: 'ALL',
          name: '전체',
        },
        {
          value: 'H',
          name: '결제대기',
        },
        {
          value: 'Y',
          name: '결제완료',
        },
        {
          value: 'P',
          name: '결제요청',
        },
        {
          value: 'CP',
          name: '취소요청',
        },
        {
          value: 'C',
          name: '취소완료',
        },
      ],
      status: {
        결제대기: 'H',
        결제완료: 'Y',
        결제요청: 'P',
        취소요청: 'CP',
        취소완료: 'C',
      },
      start_date: '',
      end_date: '',
      cate: 0,
    };
  },
  mounted() {
    this.GetReservationHistoryList();
  },
  methods: {
    GetReservationHistoryList() {
      const body = {};
      const req = CryptoJS.AES.encrypt(
        JSON.stringify(body),
        process.env.VUE_APP_SECRET_TOKEN
      ).toString();
      this.loading = true;
      this.$http
        .post('/shop/reservation/GetReservationHistoryList', { req })
        .then((res) => {
          if (res.status == 200) {
            this.loading = false;
            if (res.data.code == '200') {
              const e_body = res.data.body;
              const bytes = CryptoJS.AES.decrypt(
                e_body,
                process.env.VUE_APP_SECRET_TOKEN
              );
              const d_res = bytes.toString(CryptoJS.enc.Utf8);
              const body = JSON.parse(d_res);
              this.list = body.list;
              this.category_list = body.list;
            }
          }
        });
    },
    ChangeCategory(type) {
      this.type = type;
      this.cate = 0;
      this.start_date = '';
      this.end_date = '';

      if (type === '전체') {
        this.category_list = this.list;
        return;
      }

      const filter = this.list.filter((el) => el.status === this.status[type]);

      this.category_list = filter;
    },
    ChangeDateCategory() {
      if (this.start_date == '') {
        if (this.type === '전체') {
          this.category_list = this.list;
        } else {
          const filter = this.list.filter(
            (el) => el.status === this.status[this.type]
          );

          this.category_list = filter;
        }
        return;
      }

      const filter = this.list.filter((el) => {
        const start = new Date(this.start_date);
        const date = new Date(el.update_time.split(' ')[0]);

        if (start <= date) {
          return this.type === '전체'
            ? el
            : el.status === this.status[this.type];
        }
      });

      this.category_list = filter;
    },
    ChangeDate(cate) {
      if (cate == 0) {
        this.start_date = '';
        this.end_date = '';
        this.cate = 0;
      } else if (cate == 1) {
        const end_date = moment().format('YYYY-MM-DD');
        const start_date = moment().add(-1, 'days').format('YYYY-MM-DD');
        this.start_date = start_date;
        this.end_date = end_date;
        this.cate = 1;
      } else if (cate == 2) {
        const end_date = moment().format('YYYY-MM-DD');
        const start_date = moment().add(-7, 'days').format('YYYY-MM-DD');
        this.start_date = start_date;
        this.end_date = end_date;
        this.cate = 2;
      } else if (cate == 3) {
        const end_date = moment().format('YYYY-MM-DD');
        const start_date = moment().add(-30, 'days').format('YYYY-MM-DD');
        this.start_date = start_date;
        this.end_date = end_date;
        this.cate = 3;
      }
    },
  },
};
</script>

<style></style>
