<template>
  <div class="bg-gray-relative-100 mh-vh-100-nav p-3">
    <SubHeader title="취소대기"></SubHeader>
    <ReservationItem :list="list" />
  </div>
</template>

<script>
import SubHeader from '@/components/common/SubHeader.vue';
import ReservationItem from '@/components/reservation/ReservationItem.vue';
const CryptoJS = require('crypto-js');

export default {
  components: {
    SubHeader,
    ReservationItem,
  },
  data() {
    return {
      list: [],
    };
  },
  mounted() {
    this.GetReservationHistoryList();
  },
  methods: {
    GetReservationHistoryList() {
      const body = {};
      const req = CryptoJS.AES.encrypt(
        JSON.stringify(body),
        process.env.VUE_APP_SECRET_TOKEN
      ).toString();
      this.loading = true;
      this.$http
        .post('/shop/reservation/GetReservationHistoryCancelList', { req })
        .then((res) => {
          if (res.status == 200) {
            this.loading = false;
            if (res.data.code == '200') {
              const e_body = res.data.body;
              const bytes = CryptoJS.AES.decrypt(
                e_body,
                process.env.VUE_APP_SECRET_TOKEN
              );
              const d_res = bytes.toString(CryptoJS.enc.Utf8);
              const body = JSON.parse(d_res);
              this.list = body.list;
            }
          }
        });
    },
  },
};
</script>

<style></style>
