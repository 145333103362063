<template>
    <div class="bg-gray-relative-100 mh-vh-100-nav p-3">
        <SubHeader :title="title"></SubHeader>
        <HistoryItem :item_list="item_list" />
    </div>
</template>

<script>
import SubHeader from '@/components/common/SubHeader.vue'
import HistoryItem from '@/components/reservation/HistoryItem.vue'

export default {
    components:{
        SubHeader, HistoryItem
    },
    data(){
        return{
            title:'',
            list:[],
            item_list:[ 
                {
                    user_name: '김현수',
                    user_contact: '010-1234-5678',
                    day: '2023-05-06',
                    reserve_time: 1,
                    status: '결제승인',
                },
                {
                    user_name: '김현수',
                    user_contact: '010-1234-5678',
                    day: '2023-05-08',
                    reserve_time: 1,
                    status: '결제승인',
                },
                {
                    user_name: '진자성',
                    user_contact: '010-1234-5678',
                    day: '2023-05-08',
                    reserve_time: 1,
                    status: '결제취소',
                },
                {
                    user_name: '박수환',
                    user_contact: '010-1234-5678',
                    day: '2023-05-07',
                    reserve_time: 2,
                    status: '결제취소',
                },
            ]
        }
    },
    mounted(){
        const currentDate = new Date()
        const today = new Date().toISOString().split('T')[0];
        
        const startOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
        const endOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);

        switch(this.$route.params.date){
            case 'ta':
                this.title= '오늘 승인내역'
                this.list = this.item_list.filter(item => item.day === today && item.status === '결제승인');
            break
            case 'ma':
                this.title= '이번 달 승인내역'
                this.list = this.item_list.filter(item => {
                    const itemDate = new Date(item.day);
                    const isCurrentMonth = itemDate >= startOfMonth && itemDate <= endOfMonth;
                    return isCurrentMonth && item.status === '결제승인'
                });
            break
            case 'tc':
                this.title= '오늘 취소내역'
                this.list = this.item_list.filter(item => item.day === today && item.status === '결제취소');
            break
            case 'mc':
                this.title= '이번 달 취소내역'
                this.list = this.item_list.filter(item => {
                    const itemDate = new Date(item.day);
                    const isCurrentMonth = itemDate >= startOfMonth && itemDate <= endOfMonth;
                    return isCurrentMonth && item.status === '결제취소'
                });
            break
        }
    },
    methods:{
		TimeFormmat(time_str){
            if(time_str == 1){
                return '오전'
            }else{
                return '오후'
            }
		},
	    DayFormat(day_str){
            const days = ['일', '월', '화', '수', '목', '금', '토'];
            const date = new Date(day_str);
            return days[date.getDay()];
		},
    }
}
</script>

<style>

</style>